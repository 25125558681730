
    import _cloneDeep from 'lodash/cloneDeep';
    import { validEmail, phoneValidator } from '@/utils/form';
    import SuccessDialogVue from '@/components/shared/SuccessDialog.vue';

    const defaultForm = {
        fullname: '',
        phone: '',
        email: '',
        date: '',
        doctor: '',
        symptom: '',
    };

    export default {
        components: {
            SuccessDialogVue,
        },
        data() {
            return {
                visible: false,
                loading: false,
                form: _cloneDeep(defaultForm),
                rules: {
                    fullname: [{ required: true, message: 'Vui lòng nhập tên của bạn', trigger: 'blur' }],
                    phone: [
                        { required: true, message: 'Vui lòng nhập số điện thoại của bạn', trigger: 'blur' },
                        { validator: phoneValidator },
                    ],
                    email: [{
                        required: true, validator: validEmail, message: 'Vui lòng nhập email của bạn', trigger: 'blur',
                    }],
                    date: [{ required: true, message: 'Vui lòng chọn ngày khám', trigger: 'change' }],
                    symptom: [{ required: true, message: 'Vui lòng nhập thông tin cần tư vấn', trigger: 'blur' }],
                },
            };
        },

        methods: {
            open() {
                this.visible = true;
            },

            close() {
                this.visible = false;
            },

            async sendForm() {
                this.$refs.contactForm.validate(async (valid) => {
                    if (valid) {
                        try {
                            await Promise.all([
                                this.$api.consultations.create({
                                    ...this.form,
                                    domain: 'benhvien16a.com',
                                }),
                                this.$axios.post('/u/tickets', {
                                    content: this.form.symptom,
                                    ...this.form,
                                    type: 'register_consultations',
                                    origin: 'benhvien16a.com',
                                })
                            ]);
                            this.$refs.SuccessDialogVue.open();
                            this.$emit('submit', {
                                ...this.form,
                                domain: 'benhvien16a.com',
                            });
                            this.$emit('submit', {
                                ...this.form,
                                type: 'register_consultations',
                                origin: 'benhvien16a.com',
                            });
                            this.form = _cloneDeep(defaultForm);
                            this.close();
                        } catch (error) {
                            this.$handleError(error);
                        }
                    }
                });
            },
        },
    };
